import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function DrunkenDuel() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/drunken-duel/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Duelo Bêbado</h1>
                                    <p>Duelo Bêbado é um divertido jogo de faroeste com controle baseado em física e acionado por um único botão, que pode ser jogado individualmente ou em dupla. No jogo, dois bonecos de pano embriagados duelam. Você pode acertar o alvo em série ao disparar aleatoriamente, mas pode perder jogando desta maneira. É possível fazer disparos mais profissionais dominando a física do jogo após algumas partidas. Os sons e efeitos visuais no jogo tornam a experiência ainda mais divertida.</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default DrunkenDuel