import React from 'react'
import { Link } from "react-router-dom";

function AreaBg() {
  return (
    SquadSingle()
  )
}

function SquadSingle() {
  return (
    <section className="our-squad-area black-bg">
    <div className="container">
      <div className="row justify-content-center">


      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/corte-a-corda-2"><img src="https://games.assets.gamepix.com/40214/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Corte a Corda 2</h4>
                <span>quebra-cabeça</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/boxe-bebado-2"><img src="https://games.assets.gamepix.com/20XI2/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Boxe Bêbado 2</h4>
                <span>multijogador</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/pequena-tumba"><img src="https://games.assets.gamepix.com/78608/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Pequena Tumba</h4>
                <span>quebra-cabeça</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/mestres-do-futebol"><img src="https://games.assets.gamepix.com/2TM7A/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Mestres do Futebol</h4>
                <span>multijogador</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/salve-desenhando"><img src="https://games.assets.gamepix.com/2R2U6/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Salve Desenhando</h4>
                <span>quebra-cabeça</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/estrelas-do-basquete"><img src="https://games.assets.gamepix.com/35LBE/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Estrelas do Basquete</h4>
                <span>multijogador</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/cabeca-fria-2"><img src="https://games.assets.gamepix.com/40420/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Cabeça Fria 2</h4>
                <span>quebra-cabeça</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/duelo-bebado"><img src="https://games.assets.gamepix.com/UR833/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Duelo Bêbado</h4>
                <span>multijogador</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </div>
  </section>

  )
}

export default AreaBg