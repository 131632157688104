import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function CutTheRope2() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">
                                        
                                    <iframe src="https://play.gamepix.com/cut-the-rope-2/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Corte a Corda 2</h1>
                                    <p>Uma Nova Aventura com Om Nom! Entre na diversão com Om Nom no jogo Cut the Rope 2 HTML5. Experimente novos personagens e elementos de jogabilidade inovadores em missões desafiadoras. A coleta de doces atinge um novo nível de diversão! Enfrente desafios frescos e obstáculos inesperados neste fenômeno baseado em física que já encantou milhões de jogadores em todo o mundo. Se você é fã de Cut the Rope, vai se apaixonar por Cut the Rope 2! Venha e junte-se à aventura!</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default CutTheRope2