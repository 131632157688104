import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function DrunkenBoxing2() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/drunken-boxing-2/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">BOXE BÊBADO 2</h1>
                                    <p>Prepare-se para a ação com BOXE BÊBADO 2! Este jogo inovador, destinado aos verdadeiros amantes do boxe, oferece uma experiência de jogo única com suas animações renovadas, efeitos impressionantes e gráficos 3D. Assuma o controle dos Boxeadores Bêbados e desfira golpes rápidos no seu oponente. Mas cuidado! Fique de olho na sua Barra de Energia. Se você ficar sem energia, precisará de um tempo para se recuperar. Domine a arte de movimentos para frente e para trás, desfira golpes rápidos e conquiste a vitória! Com modos 1P e 2P, Drunken Boxing é perfeito para jogar sozinho ou com um amigo. O primeiro a atingir uma pontuação de 5 vence a partida! Pronto para entrar no ringue? Controles do Jogo: JOGADOR 1: “TECLAS DE SETA” JOGADOR 2: “W,A,S,D”</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default DrunkenBoxing2