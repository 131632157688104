import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function BasketballStars() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/basketball-stars/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Estrelas do Basquete</h1>
                                    <p>Estrelas do Basquete: Um emocionante jogo de basquete para dois jogadores. Jogue com sua equipe favorita e com as lendas do basquete. Desafie seus amigos no modo para dois jogadores ou teste suas habilidades no modo individual. Mostre seu talento no basquete, faça um arremesso de três pontos ou uma enterrada impressionante! Experimente jogar com diversas equipes lendárias e teste suas habilidades defensivas e ofensivas. Venha jogar Estrelas do Basquete e entre para o hall da fama do basquete!</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default BasketballStars