import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function FootballMasters() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/football-masters/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Mestres do Futebol</h1>
                                    <p>Mestres do Futebol: Entre no campo com o seu time favorito e desafie competidores no nosso emocionante jogo de futebol online. Seja jogando solo ou em equipe com amigos, cada partida é uma nova oportunidade para mostrar suas habilidades de chute e levar seu time à vitória. Junte-se a nós na diversão do futebol e mostre ao mundo do que você é capaz!</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default FootballMasters