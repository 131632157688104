import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function TinyTomb() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/tiny-tomb/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Pequena Tumba</h1>
                                    <p>Pequena Tumba é um daqueles jogos que realmente te transportam:

Quatro mundos etéreos lindamente renderizados flutuam na borda do universo. Para salvar seus amigos, você terá que rastejar por todos eles… enquanto também mantém um monstro mal-humorado e exigente bem alimentado.

(No seu caminho, tente descobrir por que Birdman está tão confuso)

Tiny Tombs apresenta a exploração de masmorras clássica como deveria ser, acompanhada de: uma história fora do comum com personagens ótimos e peculiares, arte voxel clássica e fofa, animações estilo stop-motion, tumbas sempre mudando, mais de 25 itens especiais para coletar e 4 mundos loucos e sonhadores para explorar.
Quantidades loucas de diversão estão esperando neste jogo de ritmo acelerado e fácil de começar. Para longas sessões de jogo ou explosões curtas, este jogo entrega.</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default TinyTomb