import React from 'react'

function Footer() {
  return (
    <footer>
    <div className="footer-top-wrap">
      <div className="container">
        <div className="footer-menu-wrap">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="f-logo">
                <a href="/index-3"><img src="assets/img/logo/logo.png" alt="" /></a>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="footer-menu">
                <nav>
                  <ul className="navigation">
                    <li><a href="/">Início</a></li>
                    <li><a href="/multijogador">Multijogador</a></li>
                    <li><a href="/quebra-cabeca">Quebra-Cabeça</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-quick-link-wrap">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-8">
              
            <div className="copyright-text">
              <p>© 2023 - 2024 Otavio Ortiz</p>
            </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </footer>
  )
}

export default Footer