
import { useEffect } from 'react';
// import $ from "jquery";
import { Routes, Route } from "react-router-dom";

import IndexOne from './pages/IndexOne';
import Multijogador from './pages/Multijogador';
import DrunkenBoxing2 from './pages/games/multiplayer/DrunkenBoxing2';
import $ from "jquery";
import FootballMasters from './pages/games/multiplayer/FootballMasters';
import BasketballStars from './pages/games/multiplayer/BasketballStars';
import DrunkenDuel from './pages/games/multiplayer/DrunkenDuel';
import Puzzle from './pages/Puzzle';
import CutTheRope2 from './pages/games/puzzle/CutTheRope2';
import TinyTomb from './pages/games/puzzle/TinyTomb';
import DrawSavePuzzle from './pages/games/puzzle/DrawSavePuzzle';
import IcyPurpleHead2 from './pages/games/puzzle/IcyPurpleHead2';

function App() {

  useEffect(()=>{

    function preloader() {
      $('#preloader').delay(0).fadeOut();
    };
    
    $(window).on('load', function () {

      preloader();
     
    });
    
  },[])
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<IndexOne/>}/>
        <Route path="multijogador" element={<Multijogador/>}/>
        <Route path="quebra-cabeca" element={<Puzzle/>}/>

        <Route path="corte-a-corda-2" element={<CutTheRope2/>}/>
        <Route path="pequena-tumba" element={<TinyTomb/>}/>
        <Route path="salve-desenhando" element={<DrawSavePuzzle/>}/>
        <Route path="cabeca-fria-2" element={<IcyPurpleHead2/>}/>
        
        <Route path="boxe-bebado-2" element={<DrunkenBoxing2/>}/>
        <Route path="mestres-do-futebol" element={<FootballMasters/>}/>
        <Route path="estrelas-do-basquete" element={<BasketballStars/>}/>
        <Route path="duelo-bebado" element={<DrunkenDuel/>}/>
      </Routes>
    </div>
  );
}

export default App;
