import React from 'react'
import Footer from '../components/Footer'
import HeaderOne from '../components/header/HeaderOne'
import PuzzleCategory from '../components/categories/PuzzleCategory'

function Puzzle() {
  return (
   <>
   <HeaderOne/>
        <main>
            <PuzzleCategory/>
        </main>
   <Footer/>
   </>
  )
}

export default Puzzle