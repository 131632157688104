import React from 'react'
import Footer from '../components/Footer'
import HeaderOne from '../components/header/HeaderOne'
import MultiplayerCategory from '../components/categories/MultiplayerCategory'

function Squad() {
  return (
   <>
   <HeaderOne/>
        <main>
            <MultiplayerCategory/>
        </main>
   <Footer/>
   </>
  )
}

export default Squad