import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function IcyPurpleHead2() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/icy-purple-head/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Cabeça Roxa Gelada 2</h1>
                                    <p>Embarque em uma aventura congelante com "Cabeça roxa gelada 2". Toque em qualquer lugar na tela para se transformar em uma figura de gelo deslizante. Navegue habilmente pelo cenário do jogo para alcançar a caixa de correio, marcando assim a conclusão do nível. Mas cuidado! Evite os espinhos perigosos e a eletricidade pulsante para manter-se seguro. Junte-se a nós neste emocionante jogo online!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default IcyPurpleHead2