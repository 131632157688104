import React from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";

function MultiplayerCategory() {
  return (
<div>
    <section className="breadcrumb-area breadcrumb-bg">
        <div className="breadcrumb-overflow">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="breadcrumb-content">
                    <h2 className="title">Multijogador</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Multijogador</li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="col-md-6 d-none d-md-block">
                  <div className="breadcrumb-img text-center text-lg-end">
                    <img src="assets/img/images/multiplayer.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <motion.div 
           initial={{ x: -380,opacity:0}}
           animate={{ x: 0, opacity: 1 }} 
       
           transition={{
             delay: 0.2,
             duration: 2.5 ,
           }}
          className="breadcrumb-shape shape-1 wow slideInLeft" data-wow-delay=".2s" data-wow-duration="2.5s"><img src="assets/img/bg/breadcrumb_shape01.png" alt="" /></motion.div>
          <motion.div
          initial={{ x: 380,opacity:0}}
          animate={{ x: 0, opacity: 1 }} 
     
          transition={{
            delay: 0.2,
            duration: 2.5 ,
          }}
          className="breadcrumb-shape shape-2 wow slideInRight" data-wow-delay=".2s" data-wow-duration="2.5s"><img src="assets/img/bg/breadcrumb_shape02.png" alt="" /></motion.div>
        </div>
      </section>

      <section className="our-squad-area black-bg">
    <div className="container">
      <div className="row justify-content-center">

      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/boxe-bebado-2"><img src="https://games.assets.gamepix.com/20XI2/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Boxe Bêbado 2</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/mestres-do-futebol"><img src="https://games.assets.gamepix.com/2TM7A/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Mestres do Futebol</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/estrelas-do-basquete"><img src="https://games.assets.gamepix.com/35LBE/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Estrelas do Basquete</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
          <div className="squad-item">
            <div className="squad-thumb">
            <Link to="/duelo-bebado"><img src="https://games.assets.gamepix.com/UR833/banner/medium.png" alt="" /></Link>
            </div>
            <div className="squad-content">
              <div className="squad-info">
                <h4 className="title">Duelo Bêbado</h4>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>
  )


}

export default MultiplayerCategory