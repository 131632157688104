import React from 'react'
import Footer from '../../../components/Footer'
import HeaderOne from '../../../components/header/HeaderOne'

function DrawSavePuzzle() {
  return (
    <>
        <HeaderOne/>
            <main>
                <section className="blog-details-area black-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item mb-60">
                                    <div className="blog-thumb mb-50">

                                    <iframe src="https://play.gamepix.com/draw-save-puzzle/embed?sid=O18TS" width="960" height="640" frameborder="0" scrolling="no"></iframe>
                                    
                                    </div>
                                    <div className="blog-content blog-details-content">
                                    <h1 className="title">Salve Desenhando</h1>
                                    <p>Embarque em uma aventura emocionante com “Salve Desenhando”, um jogo online inovador que desafia sua criatividade e habilidades de resolução de problemas. Neste jogo, você tem o poder de alterar o destino de um personagem com o simples traço de uma linha.

No “Salve Desenhando”, cada nível apresenta um novo desafio onde o personagem está em perigo iminente. Seja um abismo profundo, um mar de lava borbulhante ou uma chuva de flechas, cabe a você desenhar uma linha de segurança para salvar o personagem da morte certa.

Este jogo é mais do que apenas desenhar linhas. É sobre estratégia, precisão e pensamento rápido. Você precisa decidir onde desenhar a linha, qual a forma e o comprimento que ela deve ter para garantir a segurança do personagem. E lembre-se, você tem um tempo limitado para fazer isso!</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>

        
    </>
  )
}

export default DrawSavePuzzle